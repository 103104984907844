import React from 'react'
import { useControlled } from '../utils'

function Slider({
  id,
  min = 0,
  max = 100,
  step = 1,
  value: controlledValue,
  onChange,
  valueFn,
  popupBottom = false,
  disabled = false,
}) {
  const [value, setValue] = useControlled({
    controlledValue,
  })

  const handleChange = (e) => {
    setValue(Number(e.target.value))
    onChange?.(Number(e.target.value))
  }

  return (
    <div className="slider">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        id="fader"
        step={step}
        list={`volsettings-${id}`}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />
      <span
        className={`current-value ${popupBottom ? 'bottom' : ''} ${
          disabled ? 'disabled' : ''
        }`}
        style={{
          left: `${((value - min) * 100) / (max - min)}%`,
        }}
      >
        {valueFn?.(value) || value}
      </span>
      <span className="min-value">{valueFn?.(min) || min}</span>
      <span className="max-value">{valueFn?.(max) || max}</span>
    </div>
  )
}

export default Slider
