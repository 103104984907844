import { useQuery } from 'react-query'
import axios from 'axios'
import pako from 'pako'

const FILE_SERVER_DOMAIN = 'https://gic-files.atmosphericiq.com'

export function useSolarEvents() {
  const { data, isLoading, isError, error } = useQuery(
    'solar-events',
    async () => {
      const { data: manifest } = await axios.get(
        `${FILE_SERVER_DOMAIN}/manifest.json`,
        { responseType: 'json' }
      )

      const data = await Promise.all(
        manifest.storm_data.map((storm, id) =>
          axios
            .get(FILE_SERVER_DOMAIN + storm.location, {
              responseType: 'arraybuffer',
              decompress: true,
            })
            .then(({ data }) =>
              JSON.parse(pako.inflate(data, { to: 'string' }))
            )
            .then((data) => ({
              id,
              name: data.name,
              location: data.location.name,
              background: data.background,
              latitude: Number(data.location.latitude),
              longitude: Number(data.location.longitude),
              frequency: Number(data.resolution_hz),
              start_time: data.start_at,
              data: data.data,
              url: data.url,
            }))
        )
      )

      return data
    }
  )

  return { data, isLoading, isError, error }
}
