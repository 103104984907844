import React, { useEffect } from 'react'

const Canvas = ({ draw, onMouseWheel }) => {
  const canvasRef = React.useRef()

  useEffect(() => {
    const resize = () => {
      const canvas = canvasRef.current
      const { width, height } = canvas.parentElement.getBoundingClientRect()
      canvas.width = width
      canvas.height = height
    }
    resize()

    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current

    const context = canvas.getContext('2d')
    // let animationFrameId

    const render = () => {
      const { width, height } = canvas.getBoundingClientRect()
      draw(context, width, height)
      // if (requestFlag) animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      // window.cancelAnimationFrame(animationFrameId)
    }
    // }, [draw, requestFlag])
  }, [draw])

  return <canvas ref={canvasRef} onWheel={onMouseWheel} />
}

export default Canvas
