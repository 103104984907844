import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import Select from 'react-select'
import {
  FaInfoCircle,
  FaPause,
  FaPlay,
  FaTrashAlt,
  FaForward,
  FaBackward,
} from 'react-icons/fa'
import Canvas from './components/Canvas'
import Slider from './components/Slider'
import SettingControl from './components/SettingControl'

import './App.css'
import Axes from './components/Axes'
import { useSolarEvents } from './hooks/useSolarEvents'

const defaultTimeScale = 1000
const maxTimeScale = 10000

const simulationSpeedSamples = {
  1: 1,
  2: 5,
  4: 10,
  8: 50,
  16: 200,
  32: 800,
  64: 1600,
}

function AppContent({ solarEvents }) {
  const defaultSettings = {
    pipeDiameter: 20,
    pipeWallThickness: 20,
    pipelineLength: 20,
    solarEvent: {
      label: solarEvents[0].name,
      value: solarEvents[0].id,
    },
    pipeDepth: 1.5,
    cathodicProtectionLevel: {
      label: 'AMPP Standard (-850 mV)',
      value: '-850',
    },
    bearing: 0,
  }

  const [settings, setSettings] = useState(defaultSettings)
  const [playing, setPlaying] = useState(false)
  const [simulationIndex, setSimulationIndex] = useState(0)
  const [timeScale, setTimeScale] = useState(defaultTimeScale)
  const [simulationSpeed, setSimulationSpeed] = useState(1)
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(true)
  const [scopeField, setScopeField] = useState(-1)

  const solarEvent = solarEvents[settings.solarEvent.value]
  const timeElapsed = simulationIndex / solarEvent.frequency

  const p2sValue = useMemo(() => {
    const result = []
    const { data } = solarEvent

    for (let i = 0; i < data.length; i++) {
      const change =
        i === 0 ? 0 : Math.hypot(...data[i]) - Math.hypot(...data[i - 1])
      const totalInducedVoltage =
        change *
        settings.pipeDiameter *
        0.0254 *
        settings.pipelineLength *
        Math.pow(10, -6) *
        solarEvent.frequency
      // offset
      result[i] = totalInducedVoltage
    }

    return result
  }, [settings.pipeDiameter, settings.pipelineLength, solarEvent])

  const timerRef = useRef()

  const resetSettings = () => {
    setSettings(defaultSettings)
    setSimulationIndex(0)
    setSimulationSpeed(1)
    setTimeScale(defaultTimeScale)
  }

  const draw = (ctx, width, height, p2s, isTotal = 0) => {
    const { data, frequency } = solarEvent

    const value = (i) => {
      if (i >= data.length) return value(data.length - 1)
      if (p2s)
        return isTotal
          ? p2sValue[i]
          : p2sValue[i] + Number(settings.cathodicProtectionLevel.value) * 0.001
      return i === 0
        ? 0
        : scopeField === -1
        ? Math.hypot(...data[i]) - Math.hypot(...data[i - 1])
        : data[i][scopeField] - data[i - 1][scopeField]
    }

    //clear background
    ctx.clearRect(0, 0, width, height)

    if (!isTotal) {
      // fill background
      ctx.fillStyle = '#555151'
      ctx.fillRect(0, 0, width, height)

      // draw center line
      ctx.beginPath()
      ctx.lineWidth = '1'
      ctx.strokeStyle = '#697386'
      ctx.moveTo(0, height / 2)
      ctx.lineTo(width, height / 2)
      ctx.stroke()

      // draw quater line (only for p2s)
      if (p2s) {
        ctx.beginPath()
        ctx.lineWidth = '1'
        ctx.strokeStyle = '#aaa3'
        ctx.moveTo(0, height / 4)
        ctx.lineTo(width, height / 4)
        ctx.stroke()

        ctx.beginPath()
        ctx.lineWidth = '1'
        ctx.strokeStyle = '#aaa3'
        ctx.moveTo(0, (3 * height) / 4)
        ctx.lineTo(width, (3 * height) / 4)
        ctx.stroke()
      }
    }

    const timeElaspedFloor = Math.round(simulationIndex) / solarEvent.frequency
    // prepare data for showing on graph
    let stTime = timeElaspedFloor - timeScale,
      enTime = timeElaspedFloor
    let stIndex = Math.round(simulationIndex) - timeScale * frequency,
      enIndex = Math.round(simulationIndex)
    let stPoint = 0,
      enPoint = width

    if (stTime < 0) {
      stTime = 0
      enTime = timeElaspedFloor
      stIndex = 0
      enIndex = enTime * frequency
      stPoint = width - (width * timeElaspedFloor) / timeScale
    }

    if (isTotal) {
      stIndex = 0
      enIndex = data.length - 1
      stPoint = 0
      enPoint = width
      stTime = 0
      enTime = Math.round(data.length / frequency)
    }

    let max_value = p2s ? 2 : 0
    if (!p2s) {
      for (let i = stIndex; i <= enIndex; i++) {
        max_value = Math.max(Math.abs(value(i)), max_value)
      }
      max_value *= 1.1
    }

    const convert2y = (v) => height / 2 - (v * height) / max_value / 2

    if (!isTotal) {
      // draw vertical lines
      ctx.beginPath()
      ctx.lineWidth = '1'
      ctx.strokeStyle = '#697386'
      for (let i = 0; i < 10; i++) {
        const x = (width * i) / 10
        ctx.moveTo(x, 0)
        ctx.lineTo(x, height)
      }
      ctx.stroke()
    }

    // draw graph
    ctx.beginPath()
    ctx.lineWidth = '1'
    ctx.strokeStyle = '#00FF29'
    if (!p2s && !isTotal) ctx.strokeStyle = '#ff0000'
    if (!p2s && isTotal) ctx.strokeStyle = '#555151'

    ctx.moveTo(stPoint, convert2y(value(stIndex)))
    for (let i = stIndex; i <= enIndex; i++) {
      ctx.lineTo(
        stPoint +
          ((i / frequency - stTime) * (enPoint - stPoint)) / (enTime - stTime),
        convert2y(value(i))
      )
    }
    ctx.stroke()

    // draw center line value text
    if (!isTotal && Math.round(simulationIndex) > 0) {
      const label = p2s ? '0 mV' : '0 nT'

      ctx.font = '14px'
      ctx.fillStyle = '#ffffff'
      ctx.fillText(label, width - 27, height / 2 - 5)
    }

    // draw min/max value and current value
    if (!isTotal && Math.round(simulationIndex) > 0) {
      ctx.font = '14px'
      ctx.fillStyle = '#ffffff'
      if (p2s) {
        ctx.fillText('-2000 mV', width - 48, height - 5)
        ctx.fillText('2000 mV', width - 44, 12)
        ctx.fillText(
          `V = ${Number(value(Math.round(simulationIndex)) * 1000).toFixed(
            4
          )} mV`,
          10,
          12
        )
      } else {
        if (!Number.isNaN(max_value)) {
          ctx.fillText(`-${max_value.toFixed(3)} nT`, width - 48, height - 5)
          ctx.fillText(`${max_value.toFixed(3)} nT`, width - 44, 12)
          ctx.fillText(
            `∂ ${scopeField === -1 ? 'F' : 'XYZ'[scopeField]} = ${value(
              Math.round(simulationIndex)
            ).toFixed(3)} nT`,
            10,
            12
          )
        }
      }
    }
  }

  const drawMagFlux = (ctx, width, height) => draw(ctx, width, height, 0)
  const drawP2S = (ctx, width, height) => draw(ctx, width, height, 1)
  const drawFluxTotal = useCallback(
    (ctx, width, height) => draw(ctx, width, height, 0, 1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [solarEvent, settings, scopeField]
  )

  const handleMouseWheel = (e) => {
    if (e.deltaY > 0) {
      setTimeScale(Math.max(timeScale - 500, defaultTimeScale))
    }
    if (e.deltaY < 0) {
      setTimeScale(Math.min(timeScale + 500, maxTimeScale))
    }
  }

  const timeTick = useCallback(() => {
    setSimulationIndex((i) => {
      const event = solarEvents[settings.solarEvent.value]
      if (i >= event.data.length - 1) {
        setPlaying(false)
        clearInterval(timerRef.current)
        return i
      }
      const step =
        (simulationSpeedSamples[simulationSpeed] / 16) * solarEvent.frequency
      if (i + step >= event.data.length - 1) {
        return event.data.length - 1
      }
      return i + step
    })
  }, [settings.solarEvent.value, solarEvent.frequency, simulationSpeed])

  useEffect(() => {
    if (playing) {
      clearInterval(timerRef.current)
      timerRef.current = setInterval(timeTick, 62.5)
    }
  }, [timeTick, playing])

  const curDate = new Date(
    new Date(solarEvents[settings.solarEvent.value].start_time).getTime() +
      Math.round(timeElapsed) * 1000
  )

  return (
    <div className="app-container">
      <div
        className={`main-section ${
          solarEvents[settings.solarEvent.value].background
        }`}
      >
        <div className="test-point">
          <p>Test Point</p>
        </div>
        <div className="pipe">
          <p>
            Pipeline (Steel)
            <br />
            {settings.pipeDiameter}in Diameter
            <br />
            {settings.pipeWallThickness}in Wall Thickness
          </p>
        </div>
        <div className="mag-obs">
          <p>
            Ground Based
            <br />
            Magnetic
            <br />
            Observatory
          </p>
        </div>
        <Axes
          meax={solarEvent.data[Math.round(simulationIndex)][0].toFixed(2)}
          meay={solarEvent.data[Math.round(simulationIndex)][1].toFixed(2)}
          meaz={solarEvent.data[Math.round(simulationIndex)][2].toFixed(2)}
          bearing={settings.bearing}
        />
      </div>
      <div className={`settings-section  ${playing ? 'playing' : ''}`}>
        <div className="header-container">
          <p>Settings</p>
          <button
            className={`reset ${playing ? 'disabled' : ''}`}
            onClick={() => {
              if (playing) return
              resetSettings()
            }}
          >
            <FaTrashAlt />
            <span>Reset</span>
          </button>
        </div>
        <div className="body-container">
          <SettingControl label="Solar Event">
            <Select
              options={solarEvents.map((event) => ({
                label: event.name,
                value: event.id,
              }))}
              value={settings.solarEvent}
              onChange={(value) => {
                setSettings((settings) => ({
                  ...settings,
                  solarEvent: value,
                }))
                setSimulationIndex(0)
                setSimulationSpeed(1)
              }}
              isDisabled={playing}
            ></Select>
          </SettingControl>
          <SettingControl label="Level of Cathodic Protection">
            <Select
              options={[
                {
                  label: 'AMPP Standard (-850 mV)',
                  value: '-850',
                },
                {
                  label: 'Unprotected Steel (-600 mV)',
                  value: '-600',
                },
              ]}
              value={settings.cathodicProtectionLevel}
              onChange={(value) =>
                setSettings((settings) => ({
                  ...settings,
                  cathodicProtectionLevel: value,
                }))
              }
              menuPlacement="top"
              isDisabled={playing}
            ></Select>
          </SettingControl>
          <SettingControl label="Pipe Diameter (in)">
            <Slider
              min={12}
              max={36}
              step={2}
              value={settings.pipeDiameter}
              onChange={(value) =>
                setSettings((settings) => ({
                  ...settings,
                  pipeDiameter: value,
                }))
              }
              disabled={playing}
            />
          </SettingControl>
          <SettingControl label="Pipe Wall Thickness (mm)">
            <Slider
              min={12}
              max={28}
              step={2}
              value={settings.pipeWallThickness}
              onChange={(value) =>
                setSettings((settings) => ({
                  ...settings,
                  pipeWallThickness: value,
                }))
              }
              disabled={playing}
            />
          </SettingControl>
          <SettingControl label="Pipeline Length (km)">
            <Slider
              min={1}
              max={200}
              step={1}
              value={settings.pipelineLength}
              onChange={(value) =>
                setSettings((settings) => ({
                  ...settings,
                  pipelineLength: value,
                }))
              }
              disabled={playing}
            />
          </SettingControl>
          <SettingControl label="Pipe Depth (m)">
            <Slider
              min={0}
              max={5}
              step={0.5}
              value={settings.pipeDepth}
              onChange={(value) =>
                setSettings((settings) => ({
                  ...settings,
                  pipeDepth: value,
                }))
              }
              disabled={playing}
            />
          </SettingControl>
          <SettingControl
            label={`Bearing (${(function (v) {
              if (v < 30) return 'North - South'
              if (v < 60) return 'North West - South East'
              if (v < 120) return 'East - West'
              if (v < 150) return 'North East - South West'
              return 'North - South'
            })(settings.bearing)})`}
          >
            <Slider
              min={0}
              max={180}
              step={1}
              value={settings.bearing}
              onChange={(value) =>
                setSettings((settings) => ({
                  ...settings,
                  bearing: value,
                }))
              }
              disabled={playing}
            />
          </SettingControl>
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-item mag-graph">
          <div className="header-container">
            <p>Magnetic Flux</p>
            <button
              className={`scope-field-button field-x ${
                scopeField === 0 && 'active'
              }`}
              onClick={() => setScopeField(0)}
            >
              X
            </button>
            <button
              className={`scope-field-button field-y ${
                scopeField === 1 && 'active'
              }`}
              onClick={() => setScopeField(1)}
            >
              Y
            </button>
            <button
              className={`scope-field-button field-z ${
                scopeField === 2 && 'active'
              }`}
              onClick={() => setScopeField(2)}
            >
              Z
            </button>
            <button
              className={`scope-field-button field-f ${
                scopeField === -1 && 'active'
              }`}
              onClick={() => setScopeField(-1)}
            >
              F
            </button>
            <a
              href="https://www.engineeringdirector.com"
              target="_blank"
              rel="noreferrer"
            >
              <FaInfoCircle />
            </a>
          </div>
          <div className="body-container">
            <Canvas draw={drawMagFlux} onMouseWheel={handleMouseWheel} />
          </div>
        </div>
        <div className="bottom-item p2s-graph">
          <div className="header-container">
            <p>Pipe-to-Soil (Test Point)</p>
            <a
              href="https://www.engineeringdirector.com"
              target="_blank"
              rel="noreferrer"
            >
              <FaInfoCircle />
            </a>
          </div>
          <div className="body-container">
            <Canvas draw={drawP2S} onMouseWheel={handleMouseWheel} />
          </div>
        </div>
        <div className="bottom-item time-location">
          <div className="header-container">
            <p>Time & Location</p>
          </div>
          <div className="body-container">
            <div>
              <p>
                <span>Time Elapsed</span>
                <span>
                  {Math.round(timeElapsed)} /{' '}
                  {solarEvent.data.length / solarEvent.frequency} sec
                </span>
              </p>
              <p>
                <span>Simulation Time</span>
                <span>
                  {curDate.toLocaleDateString() +
                    ' ' +
                    curDate.toLocaleTimeString()}
                </span>
              </p>
              <p>
                <span>Play Speed</span>
                <span>x{simulationSpeed}</span>
              </p>
              <p>
                <span>Zoom Scale</span>
                <span>x{timeScale / defaultTimeScale}</span>
              </p>
              <p>
                <span>Resolution</span>
                <span>{solarEvent.frequency}hz</span>
              </p>
            </div>
            <div>
              <a href={solarEvent.url} target="_blank" rel="noreferrer">
                <p>{solarEvents[settings.solarEvent.value].location}</p>
              </a>
              <p>
                {solarEvents[settings.solarEvent.value].latitude},
                {solarEvents[settings.solarEvent.value].longitude}
              </p>
            </div>
          </div>
        </div>
        <div className="bottom-item play-controls">
          <div className="header-container">
            <p>Play Controls</p>
          </div>
          <div className="body-container">
            <div className="playback-controls">
              <FaBackward
                size={70}
                cursor="pointer"
                onClick={() => {
                  if (!playing) return
                  setSimulationSpeed((s) => {
                    const newSpeed = s === 1 ? 1 : s / 2
                    return newSpeed
                  })
                }}
                className={`fast-forward svg-button
              ${!playing || simulationSpeed === 1 ? 'disabled' : ''}`}
              ></FaBackward>
              <FaPause
                size={70}
                cursor="pointer"
                onClick={() => {
                  if (!playing) return
                  setPlaying(false)
                  clearInterval(timerRef.current)
                }}
                className={`pause svg-button ${!playing ? 'disabled' : ''}`}
              ></FaPause>
              <FaPlay
                cursor="pointer"
                size={70}
                onClick={() => {
                  if (playing) return
                  if (
                    simulationIndex >=
                    solarEvents[settings.solarEvent.value].data.length - 1
                  ) {
                    setSimulationIndex(0)
                    setSimulationSpeed(1)
                  }
                  setPlaying(true)
                }}
                className={`play svg-button ${playing ? 'disabled' : ''}`}
              ></FaPlay>
              <FaForward
                size={70}
                cursor="pointer"
                onClick={() => {
                  if (!playing) return
                  setSimulationSpeed((s) => {
                    const newSpeed = s === 64 ? 64 : s * 2
                    return newSpeed
                  })
                }}
                className={`fast-forward svg-button
              ${!playing || simulationSpeed === 64 ? 'disabled' : ''}
              ${simulationSpeed !== 1 ? 'active' : ''}`}
              ></FaForward>
            </div>
            <div className="playback-slider">
              <Canvas draw={drawFluxTotal} />
              {/* {scopeField === -1 && <Canvas draw={drawFluxTotal} />} */}
              <Slider
                min={0}
                max={solarEvent.data.length}
                step={1}
                value={Math.floor(simulationIndex)}
                valueFn={(value) => Math.round(value / solarEvent.frequency)}
                popupBottom={true}
                onChange={(value) => {
                  value = Math.min(value, solarEvent.data.length - 1)
                  setSimulationIndex(value)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`disclaimer-modal-container ${
          disclaimerModalOpen ? 'open' : ''
        }`}
      >
        <div className="disclaimer-modal">
          <div className="modal-header">
            <p>Disclaimer</p>
          </div>
          <div className="modal-body">
            <p>
              This tool is an experimental playground and is provided as is. The
              mathematics behind the scenes here are available at the following
              links: <a href="#">GitHub Project</a>,{' '}
              <a href="#">Documentation PDF</a>
            </p>
            <p>
              We make no warranties or representations of any kind, express or
              implied, regarding the performance, accuracy, reliability,
              suitability or availability of the tool. We reserve the right to
              change, modify, or discontinue this tool at any time, for any or
              no reason, with or without notice. We will not be liable to you or
              any third-party for any such alteration, modification, suspension,
              or discontinuance.
            </p>
          </div>
          <div className="modal-footer">
            <button
              className="disclaimer-button"
              onClick={() => setDisclaimerModalOpen(false)}
            >
              Accept Disclaimer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function App() {
  const { data, isLoading } = useSolarEvents()
  if (isLoading || !data?.length)
    return <div className="loading-container">Loading ...</div>
  return <AppContent solarEvents={data} />
}

export default App
