import React from 'react'
import { Canvas, useThree, extend } from '@react-three/fiber'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { Html } from '@react-three/drei'
import { degToRad } from 'three/src/math/MathUtils'

extend({ TextGeometry })

function MyCamera({ x, y, z }) {
  useThree(({ camera }) => {
    camera.position.set(x, y, z)
  })
  return <perspectiveCamera />
}

function Axes({
  meax,
  meay,
  meaz,
  radius = 0.2,
  length = 5,
  coneRadius = 0.5,
  coneLength = 1,
  centerSphereRadius = 0.5,
  colors = ['#ff0000', '#01a532', '#0000ff', '#01a532'], // x, y, z, center
  bearing = 90,
}) {
  const cameraPosition = [11, 6, 11]
  const bearingOffset = 45
  bearing = degToRad(bearing + bearingOffset)

  const cylinderOffsetXAxis = [
    -length * Math.sin(bearing / 2) * Math.sin(bearing / 2),
    -length * Math.sin(bearing / 2) * Math.cos(bearing / 2),
  ]
  const coneOffsetXAxis = [
    -(2 * length + coneLength) * Math.sin(bearing / 2) * Math.sin(bearing / 2),
    -(2 * length + coneLength) * Math.sin(bearing / 2) * Math.cos(bearing / 2),
  ]
  const cylinderOffsetYAxis = [
    -length *
      Math.sin((bearing - Math.PI / 2) / 2) *
      Math.sin((bearing - Math.PI / 2) / 2),
    -length *
      Math.sin((bearing - Math.PI / 2) / 2) *
      Math.cos((bearing - Math.PI / 2) / 2),
  ]
  const coneOffsetYAxis = [
    -(2 * length + coneLength) *
      Math.sin((bearing - Math.PI / 2) / 2) *
      Math.sin((bearing - Math.PI / 2) / 2),
    -(2 * length + coneLength) *
      Math.sin((bearing - Math.PI / 2) / 2) *
      Math.cos((bearing - Math.PI / 2) / 2),
  ]

  return (
    <div className="axes-container">
      <Canvas camera={{ fov: 75, position: cameraPosition }}>
        <ambientLight intensity={0.2} />
        <directionalLight color="#ffffff" position={[0, 1, 3]} />
        <MyCamera
          x={cameraPosition[0]}
          y={cameraPosition[1]}
          z={cameraPosition[2]}
        />
        {/* center sphere */}
        <mesh
          position={[5 - length / 2, 5 - length / 2, 5 - length / 2]}
          rotation={[0, 0, Math.PI / 2]}
        >
          <sphereGeometry args={[centerSphereRadius]} />
          <meshStandardMaterial color={colors[3]} />
        </mesh>
        {/* x axis */}
        <mesh
          position={[
            5 + cylinderOffsetXAxis[0],
            5 - length / 2,
            5 - length / 2 + cylinderOffsetXAxis[1],
          ]}
          rotation={[0, bearing, Math.PI / 2]}
        >
          <cylinderGeometry args={[radius, radius, length]} />
          <meshStandardMaterial color={colors[0]} />
        </mesh>
        {/* x cone */}
        <mesh
          position={[
            5 + length / 2 + coneLength / 2 + coneOffsetXAxis[0],
            5 - length / 2,
            5 - length / 2 + coneOffsetXAxis[1],
          ]}
          rotation={[0, bearing, -Math.PI / 2]}
        >
          <coneGeometry args={[coneRadius, coneLength, 30]} />
          <meshStandardMaterial color={colors[0]} />
        </mesh>
        {/* y axis */}
        <mesh
          position={[
            5 + cylinderOffsetYAxis[0],
            5 - length / 2,
            5 - length / 2 + cylinderOffsetYAxis[1],
          ]}
          rotation={[0, bearing - Math.PI / 2, Math.PI / 2]}
        >
          <cylinderGeometry args={[radius, radius, length]} />
          <meshStandardMaterial color={colors[1]} />
        </mesh>
        {/* y cone */}
        <mesh
          position={[
            5 + length / 2 + coneLength / 2 + coneOffsetYAxis[0],
            5 - length / 2,
            5 - length / 2 + coneOffsetYAxis[1],
          ]}
          rotation={[0, bearing - Math.PI / 2, -Math.PI / 2]}
        >
          <coneGeometry args={[coneRadius, coneLength, 30]} />
          <meshStandardMaterial color={colors[1]} />
        </mesh>
        {/* z axis */}
        <mesh
          position={[
            5 - (length * 1.2) / 2,
            5 - length * 1.2,
            5 - (length * 1.2) / 2,
          ]}
        >
          <cylinderGeometry args={[radius, radius, length * 1.2]} />
          <meshStandardMaterial color={colors[2]} />
        </mesh>
        {/* z cone */}
        <mesh
          position={[
            5 - (length * 1.2) / 2,
            5 - (length * 1.2 * 3) / 2 - coneLength / 2,
            5 - (length * 1.2) / 2,
          ]}
          rotation={[Math.PI, 0, 0]}
        >
          <coneGeometry args={[0.5, coneLength, 30]} />
          <meshStandardMaterial color={colors[2]} />
        </mesh>
        {/* meax label */}
        <Html
          center
          position={[
            5 + length / 2 + coneLength + coneOffsetXAxis[0],
            5 - length / 2,
            5 - length / 2 + coneOffsetXAxis[1],
          ]}
        >
          <p
            className={`axis-label x-axis ${
              bearing > degToRad(55) && bearing <= degToRad(180) ? 'top' : ''
            }`}
            style={{ color: colors[0] }}
          >
            X: {meax} nT
          </p>
        </Html>
        {/* meay label */}
        <Html
          center
          position={[
            5 + length / 2 + coneLength / 2 + coneOffsetYAxis[0],
            5 - length / 2,
            5 - length / 2 + coneOffsetYAxis[1],
          ]}
        >
          <p
            className={`axis-label y-axis ${
              bearing > degToRad(180) && bearing <= degToRad(315) ? 'top' : ''
            }`}
            style={{ color: colors[1] }}
          >
            Y: {meay} nT
          </p>
        </Html>
        {/* meaz label */}
        <Html
          center
          position={[
            5 - (length * 1.2) / 2,
            5 - (length * 1.2 * 3) / 2 - coneLength,
            5 - (length * 1.2) / 2,
          ]}
        >
          <p className="axis-label z-axis" style={{ color: colors[2] }}>
            Z: {meaz} nT
          </p>
        </Html>
      </Canvas>
    </div>
  )
}

export default Axes
