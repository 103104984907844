import { useState, useRef, useEffect, useCallback } from 'react'

const useCanvas = (draw, options = {}) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext(options.context || '2d')
    let frameCount = 0
    let animationFrameId
    const render = () => {
      frameCount++
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw, options.context])
  return canvasRef
}

const useControlled = ({ controlledValue, initialValue }) => {
  const isControlled = controlledValue !== undefined
  const [internalValue, setInternalValue] = useState(initialValue)
  const value = isControlled ? controlledValue : internalValue

  const setValueIfUncontrolled = useCallback(
    (newValue) => {
      if (!isControlled) {
        setInternalValue(newValue)
      }
    },
    [isControlled]
  )

  return [value, setValueIfUncontrolled]
}

const generateFakeData = ({
  base = 32,
  amp = 5,
  diff = 1,
  anormal_freq = 0.1,
  length = 300,
} = {}) => {
  const data = []
  for (let i = 0; i < length; i++) {
    let value = Math.sin(i / 10) * amp + base
    let bias = Math.random() * diff - diff / 2
    if (Math.random() < anormal_freq) bias *= 10
    value += bias
    data.push(value)
  }
  return data
}

export { useCanvas, useControlled, generateFakeData }
