import React from 'react'

function SettingControl({ label, children }) {
  return (
    <div className="settings-control">
      <label className="settings-label">
        {label}
        <div className="settings-input">{children}</div>
      </label>
    </div>
  )
}

export default SettingControl
